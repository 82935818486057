import React from "react";
import { MainSection } from "./elements";
import { MainCol, MainContainer, MainRow } from "components/common";
import { Image } from "react-bootstrap";
import { goalimg } from "assets";

const GoalComp = () => {
  return (
    <MainSection>
      <MainRow className="main-dev">
        <MainCol lg={3}>
          <Image src={goalimg} fluid />
        </MainCol>

        <MainCol lg={9}>
          <h5>TEAM GOALS:</h5>
          <ul>
            <li>10,000 $FOMO SOLDIER HOLDERS</li>
            <li>FOMO SOLDIERS NFT COLLECTION</li>
            <li>FORFOMO.US MERCH SITE DROPS</li>
            <li>COMMUNITY MEME CHALLENGES</li>
            <li>COMMUNITY REWARDS</li>
            <li>CEX LISTINGS</li>
            <li>TOKEN-GATED ALPHA GUILD</li>
          </ul>
        </MainCol>
      </MainRow>
    </MainSection>
  );
};

export default GoalComp;
