import React from "react";
import { LeftDiv, MainSection, MainSocialDiv, SocialTextDiv } from "./elements";
import { Image } from "react-bootstrap";
import { joinimg, telegramicon, twittericon, downloadimg5 } from "assets";

const JoinComp = () => {
  return (
    <MainSection>
      <div className="main-dev">
        <LeftDiv>
          <h5>HELP US GET THERE</h5>
          <p>JOIN THE ARMY ON TWITTER & TELEGRAM</p>
        </LeftDiv>
        <Image src={downloadimg5} fluid />
      </div>

      <MainSocialDiv>
        <SocialTextDiv
          onClick={() => window.open("https://x.com/fomo6900", "_blank")}
        >
          <Image src={twittericon} fluid />
          <p>@x.com/fomo6900</p>
        </SocialTextDiv>

        <SocialTextDiv
          onClick={() => window.open("https://t.me/Fomo_6900", "_blank")}
        >
          <Image src={telegramicon} fluid />
          <p>t.me/Fomo_6900</p>
        </SocialTextDiv>
      </MainSocialDiv>
    </MainSection>
  );
};

export default JoinComp;
