import React from "react";
import { MainSection, Text } from "./elements";
import Marquee from "react-fast-marquee";
const MiddleTickerComp = () => {
  return (
    <MainSection>
      <Marquee>
        <Text>
          <p>
           $FOMO $FOMO $FOMO $FOMO $FOMO $FOMO
            $FOMO $FOMO $FOMO $FOMO $FOMO $FOMO $FOMO 
            $FOMO $FOMO
          </p>
        </Text>
      </Marquee>
    </MainSection>
  );
};

export default MiddleTickerComp;
