import styled from "styled-components";

export const MainSection = styled.section`
  padding-bottom: 4rem;
  p {
    color: #fff;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: center;
  }
`;

export const Text = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8rem;

  @media (max-width: 990px) {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
  }

  h5 {
    color: #fff;
    font-size: 2.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    @media (max-width: 990px) {
      font-size: 1.5rem;
      text-align: center;
    }
  }
`;

export const MainDev = styled.div`
  background: #d9d9d9;
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 87%;
  gap: 2rem;

  @media (max-width: 990px) {
    gap: 0rem;
    width: 100%;
  }

  .img-div {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  img {
    cursor: pointer;
  }
`;

export const BuyGloryBtn = styled.button`
  color: #fff;
  text-align: center;
  background: #000;
  font-size: 3rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  width: 53.5rem;
  height: 6.625rem;
  border: 6px solid #fde54d;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 4rem;

  @media (max-width: 990px) {
    font-size: 1.2rem;
    width: 22.0625rem;
    height: 4.8125rem;
  }
`;
