import styled from "styled-components";

export const FirstDiv = styled.div`
  background: #fff;
  padding: 0.3rem 0rem;
  p {
    color: #000;
    text-align: center;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 0rem;
    cursor: pointer;

    @media (max-width: 990px) {
      font-size: 12px;
    }
  }
`;

export const TickerText = styled.div`
  background: #000;

  p {
    color: #fff;
    font-size: 1.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 0rem;
  }
`;

export const LastDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
`;

export const InfoDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1.5rem;

  @media (max-width: 990px) {
    display: flex;
    flex-direction: column;
    gap: 0rem;
    justify-content: start;
    text-align: start;
  }
  h5 {
    color: #fff;
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;

    @media (max-width: 990px) {
      font-size: 1rem;
    }
  }
`;
