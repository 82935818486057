import React from "react";
import { ImgWrapper, MainSection, Title } from "./elements";
import {
  mainmemeimg,
  memeimg1,
  memeimg10,
  memeimg11,
  memeimg12,
  memeimg13,
  memeimg14,
  memeimg15,
  memeimg16,
  memeimg17,
  memeimg18,
  memeimg19,
  memeimg2,
  memeimg20,
  memeimg21,
  memeimg22,
  memeimg23,
  memeimg24,
  memeimg25,
  memeimg26,
  memeimg27,
  memeimg28,
  memeimg29,
  memeimg3,
  memeimg30,
  memeimg31,
  memeimg32,
  memeimg33,
  memeimg34,
  memeimg35,
  memeimg36,
  memeimg37,
  memeimg38,
  memeimg39,
  memeimg4,
  memeimg40,
  memeimg41,
  memeimg42,
  memeimg43,
  memeimg44,
  memeimg45,
  memeimg46,
  memeimg47,
  memeimg48,
  memeimg49,
  memeimg5,
  memeimg50,
  memeimg51,
  memeimg52,
  memeimg53,
  memeimg6,
  memeimg7,
  memeimg8,
  memeimg9,
  test,
} from "assets";
import { Gallery } from "react-grid-gallery";
import { images } from "./images";
import { Image } from "react-bootstrap";

const MemeComp = () => {
  return (
    <MainSection>
      <Title>ONE NATION. FOR GLORY ✊</Title>
      <ImgWrapper>
        <Image src={mainmemeimg} />
      </ImgWrapper>

      <div className="main-dev">
        {images.map((item) => (
          <Image src={item.src} />
        ))}
      </div>

      {/* <Gallery images={images} enableImageSelection={false} /> */}
    </MainSection>
  );
};

export default MemeComp;
