import React from "react";
import { FirstDiv, InfoDiv, LastDiv, TickerText } from "./elements";
import Marquee from "react-fast-marquee";
import { Image } from "react-bootstrap";
import { videoimg } from "assets";
import { ToastMessage } from "components/common";
const FooterComp = () => {
  const contractAddress = "0xFA46430c570b34CC54350302a2CaC3501650526e";

  const handleCopyClick = () => {
    const textarea = document.createElement("textarea");
    textarea.value = contractAddress;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);

    ToastMessage("Success", "Address copied To Clipboard", "success");
  };

  return (
    <div>
      <FirstDiv>
        <p onClick={handleCopyClick}>ETH CONTRACT ADDRESS: {contractAddress}</p>
      </FirstDiv>
      <Marquee>
        <TickerText>
          <p>
            $FOMO $FOMO $FOMO $FOMO $FOMO $FOMO $FOMO $FOMO $FOMO $FOMO $FOMO
            $FOMO $FOMO $FOMO
          </p>
        </TickerText>
      </Marquee>

      <LastDiv>
        <Image src={videoimg} width={70} height={70} />
        <InfoDiv>
          <h5>fomo6900.lol</h5>
          <h5>Fomo✊</h5>
          <h5>#FOMO</h5>
          <h5 onClick={() => window.open("https://x.com/fomo6900", "_blank")}>
            {" "}
            x.com/fomo6900
          </h5>
          <h5 onClick={() => window.open("https://t.me/Fomo_6900", "_blank")}>
            t.me/Fomo_6900
          </h5>
        </InfoDiv>
        <Image src={videoimg} width={70} height={70} />
      </LastDiv>
    </div>
  );
};

export default FooterComp;
