import styled from "styled-components";

export const MainSection = styled.section`
  padding-bottom: 4rem;

  h5 {
    color: #fff;
    font-size: 2.25rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    @media (max-width: 990px) {
      font-size: 2rem;
      text-align: center;
      margin-top: 2rem;
    }
  }

  li {
    color: #fff;
    font-size: 2.0625rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    &:not(:first-child) {
      margin-top: 1rem;
    }

    @media (max-width: 990px) {
      font-size: 1rem;
    }
  }

  .main-dev {
    margin-left: 10rem;

    @media (max-width: 767px) {
      margin-left: 0rem;
    }
  }
`;
