import React from "react";
import { ImgDiv, MainSection, Title } from "./elements";
import { Image } from "react-bootstrap";
import { roadmapimg } from "assets";

const RoadmapComp = () => {
  return (
    <MainSection>
      <Title>OUR ROADMAP ✊</Title>
      <ImgDiv>
        <Image src={roadmapimg} />
      </ImgDiv>
    </MainSection>
  );
};

export default RoadmapComp;
