import React from "react";
import {
  BuyGloryBtn,
  ImgDiv,
  MainLogoDiv,
  MainSection,
  Text,
} from "./elements";
import { dexlogo, dextoollogo, uniswapimg, videoimg } from "assets";
import { Image } from "react-bootstrap";

const UniswapComp = () => {
  return (
    <MainSection>
      <ImgDiv>
        <Image src={uniswapimg} fluid />
      </ImgDiv>
      <Text className="stroke">
        In the vast expanses of the digital cosmos, a realm where data streams
        flowed like rivers and code constructs stood tall as mountains, there
        existed an army known as the FOMO Soldiers. These were not mere
        warriors; they were seekers, journeying through the virtual realm in
        search of enlightenment and the ultimate digital treasure.
        <br />
        <br /> The FOMO Soldiers hailed from different corners of the cosmos,
        each bringing unique skills and experiences. to navigate the treacherous
        terrains of the digital world and uncover not just profit, … but,
        purpose. and the secrets that lay hidden within. <br />
        <br /> Their journey was fraught with challenges. They encountered token
        honeypots, deceptive traps designed to lure the unsuspecting and drain
        their resources. Rugs, which would suddenly pull from beneath their
        feet, sending them spiraling into the abyss of digital despair. And
        liquidity pulls, which threatened to drown them in a sea of volatility.{" "}
        <br />
        <br /> But the FOMO Soldiers were resilient. Guided by their spiritual
        compass and bound by a brotherhood stronger than any code, they overcame
        each trial, crying “FOMO” as they strengthened with every challenge.
        They shared tales of their adventures, meditated on their experiences,
        and drew strength from each other's wisdom. <br />
        <br /> Then came the legend of the $FOMO token. Whispers spoke of a
        token of unparalleled value and power, hidden in the deepest depths of
        one whale, who disguised himself as a dragon and lived in secrecy within
        a mystical cavern. This was not just any dragon, but the ancient Digital
        Dragon, guardian of the most coveted treasures of the digital realm.{" "}
        <br />
        <br /> With renewed vigor, the FOMO Soldiers embarked on their most
        daring quest to date: The Holy FOMO Journey. They traversed the
        treacherous Pixel Plains, scaled the Firewall Mountains, and delved deep
        into the heart of the dragon's cavern. <br />
        <br /> There, they found many currencies of shimmering digital gold,
        many of which seemed to be what they were looking for, but soon faded
        away into nonexistence, disintegrating into coal. It was but for one
        soldier, by name of Jonathan Sins, who ventured deep enough into the
        cavern guarded by the digital dragon. The young Jonathan Sins was a
        natural talent in every endeavor, and favorite amongst the young women
        of the cosmos. He, in bravery and great FOMO, came to the dragon's tale
        and solved the dragon’s riddle, bestowing upon him the For $FOMO token.
        Guarded by the Digital Dragon, it pulsed with an ethereal light,
        beckoning the soldiers. But this was no easy prize. The dragon, sensing
        their presence, challenged them with riddles and tests of character.{" "}
        <br />
        <br /> But the FOMO Soldiers, with their combined wisdom and unity,
        answered each riddle and proved their worth. Recognizing their pure
        intentions and indomitable spirit, the Digital Dragon bestowed upon them
        the $FOMO token. <br />
        <br /> With $FOMO in their possession, the darkness that once shrouded
        their path lifted, replaced by a radiant light that guided them home.
        The digital cosmos, recognizing their valor and integrity, rewarded them
        with riches beyond their wildest dreams and pleasures they had only
        dared to imagine. <br />
        <br /> The FOMO Soldiers had not just found a token; they had discovered
        their true selves. With the power of $FOMO, they built a utopia in the
        digital realm, where all could live in harmony, prosperity, and peace.{" "}
        <br />
        <br /> And so, in the annals of the digital cosmos, the tale of the For
        FOMO Soldiers became legendary. A testament to the power of unity,
        perseverance, and the quest for enlightenment. For in their journey For
        $FOMO, they had found not just a token, but the true meaning of FOMO
        itself.  FOMO
      </Text>

      <MainLogoDiv>
        <Image
          onClick={() =>
            window.open(
              "https://www.dextools.io/app/en/ether/pair-explorer/0xe2baa40ca4e21ea6f2b8a05851bcdf122310cd2d",
              "_blank"
            )
          }
          src={dextoollogo}
          fluid
        />
        <Image
          src={dexlogo}
          fluid
          onClick={() => window.open("https:/dex-screener.com", "_blank")}
        />
      </MainLogoDiv>

      <BuyGloryBtn onClick={() => window.open("https:/uniswap.com", "_blank")}>
        $FOMO ON UNISWAP
      </BuyGloryBtn>
    </MainSection>
  );
};

export default UniswapComp;
