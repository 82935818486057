import React from "react";
import { BuyGloryBtn, MainDev, MainSection, Text } from "./elements";
import { Image } from "react-bootstrap";
import { gloryimg } from "assets";
import {
  downloadimg1,
  downloadimg2,
  downloadimg3,
  downloadimg4,
  downloadimg5,
  downloadimg6,
  downloadimg7,
  downloadimg8,
} from "assets";
import { MainCol, MainRow } from "components/common";
const DownloadbleImageComp = () => {
  const images = [
    {
      image: downloadimg1,
      name: "Image 1",
    },
    {
      image: downloadimg2,
    },
    {
      image: downloadimg3,
    },
    {
      image: downloadimg4,
    },
    {
      image: downloadimg5,
    },
    {
      image: downloadimg6,
    },
    {
      image: downloadimg7,
    },
    {
      image: downloadimg8,
    },
  ];

  const handleImageClick = (imageURL, imageName) => {
    const link = document.createElement("a");
    link.href = imageURL;
    link.download = imageName || "image"; // Default name if no name is provided
    link.target = "_blank";
    link.click();
  };

  return (
    <MainSection>
      <Text>
        <h5>
          FOMO SOLDIER?
          <br />
          JOIN THE ARMY
        </h5>

        <Image src={gloryimg} fluid />
      </Text>
      <p>DOWNLOAD THESE TRANSPARENT PNG’S TO MAKE YOUR OWN FOMO MEMES</p>

      <div className="d-flex justify-content-center">
        <MainDev>
          {images.map((item, index) => (
            <div className="img-div">
              <img
                key={index}
                src={item.image}
                alt={`Image ${index}`}
                onClick={() => handleImageClick(item.image, item.name)}
              />
            </div>
          ))}
        </MainDev>
      </div>

      <BuyGloryBtn>$FOMO ON UNISWAP</BuyGloryBtn>
    </MainSection>
  );
};

export default DownloadbleImageComp;
