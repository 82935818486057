export const Images = {
  web3: {
    metamask: require("./web3/metamask.png")?.default,
    wallet: require("./web3/wallet.png")?.default,
  },
  login: {
    bg: require("./images/bg-img.png")?.default,
  },
};

export { default as heroimg } from "./images/heroimg.png";
export { default as twittericon } from "./images/twittericon.png";
export { default as telegramicon } from "./images/telegramicon.png";
export { default as uniswaplogo } from "./images/uniswaplogo.png";
export { default as dexlogo } from "./images/dexlogo.png";
export { default as dextoollogo } from "./images/DEXTOOLS LOGO.png";
export { default as videoimg } from "./images/videoimg.png";
export { default as gloryimg1 } from "./images/gloryimg1.png";
export { default as gloryimg2 } from "./images/gloryimg2.png";
export { default as gloryimg3 } from "./images/gloryimg3.png";
export { default as gloryimg4 } from "./images/gloryimg4.png";
export { default as gloryimg5 } from "./images/gloryimg5.png";
export { default as gloryimg6 } from "./images/gloryimg6.png";
export { default as gloryimg7 } from "./images/glory7.png";
export { default as gloryimg8 } from "./images/glory8.png";
export { default as gloryimg9 } from "./images/glory9.png";
export { default as memeimg1 } from "./images/meme1.png";
export { default as memeimg2 } from "./images/meme2.png";
export { default as memeimg3 } from "./images/meme3.png";
export { default as memeimg4 } from "./images/meme4.png";
export { default as memeimg5 } from "./images/meme5.png";
export { default as memeimg6 } from "./images/meme6.png";
export { default as memeimg7 } from "./images/meme7.png";
export { default as memeimg8 } from "./images/meme8.png";
export { default as memeimg9 } from "./images/meme9.png";
export { default as memeimg10 } from "./images/meme10.png";
export { default as memeimg11 } from "./images/meme11.png";
export { default as memeimg12 } from "./images/meme12.png";
export { default as memeimg13 } from "./images/meme13.png";
export { default as memeimg14 } from "./images/meme14.png";
export { default as memeimg15 } from "./images/meme15.png";
export { default as memeimg16 } from "./images/meme16.png";
export { default as memeimg17 } from "./images/meme17.png";
export { default as memeimg18 } from "./images/meme18.png";
export { default as memeimg19 } from "./images/meme19.png";
export { default as memeimg20 } from "./images/meme20.png";
export { default as memeimg21 } from "./images/meme21.png";
export { default as memeimg22 } from "./images/meme22.png";
export { default as memeimg23 } from "./images/meme23.png";
export { default as memeimg24 } from "./images/meme24.png";
export { default as memeimg25 } from "./images/meme25.png";
export { default as memeimg26 } from "./images/meme26.png";
export { default as memeimg27 } from "./images/meme27.png";
export { default as memeimg28 } from "./images/meme28.png";
export { default as memeimg29 } from "./images/meme29.png";
export { default as memeimg30 } from "./images/meme30.png";
export { default as memeimg31 } from "./images/meme31.png";
export { default as memeimg32 } from "./images/meme32.png";
export { default as memeimg33 } from "./images/meme33.png";
export { default as memeimg34 } from "./images/meme34.png";
export { default as memeimg35 } from "./images/meme35.png";
export { default as memeimg36 } from "./images/meme36.png";
export { default as memeimg37 } from "./images/meme37.png";
export { default as memeimg38 } from "./images/meme38.png";
export { default as memeimg39 } from "./images/meme39.png";
export { default as memeimg40 } from "./images/meme40.png";
export { default as memeimg41 } from "./images/meme41.png";
export { default as memeimg42 } from "./images/meme42.png";
export { default as memeimg43 } from "./images/meme43.png";
export { default as memeimg44 } from "./images/meme44.png";
export { default as memeimg45 } from "./images/meme45.png";
export { default as memeimg46 } from "./images/meme46.png";
export { default as memeimg47 } from "./images/meme47.png";
export { default as memeimg48 } from "./images/meme48.png";
export { default as memeimg49 } from "./images/meme49.png";
export { default as memeimg50 } from "./images/meme50.png";
export { default as memeimg51 } from "./images/meme51.png";
export { default as memeimg52 } from "./images/meme52.png";
export { default as memeimg53 } from "./images/meme53.png";
export { default as roadmapimg } from "./images/roadmapimg.png";
export { default as goalimg } from "./images/goalimg.png";
export { default as joinimg } from "./images/joinimg.png";
export { default as uniswapimg } from "./images/uniswapimg.png";
export { default as gloryimg } from "./images/gloryimg.png";
export { default as downloadimg1 } from "./images/downloadimg1.png";
export { default as downloadimg2 } from "./images/downloadimg2.png";
export { default as downloadimg3 } from "./images/downloadimg3.png";
export { default as downloadimg4 } from "./images/downloadimg4.png";
export { default as downloadimg5 } from "./images/downloadimg5.png";
export { default as downloadimg6 } from "./images/downloadimg6.png";
export { default as downloadimg7 } from "./images/downloadimg7.png";
export { default as downloadimg8 } from "./images/downloadimg8.png";
export { default as test } from "./images/test.png";
export { default as mainmemeimg } from "./images/mainmemeimg.png";

export { default as cursor } from "./images/cursor.jpeg";
