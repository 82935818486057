import styled from "styled-components";

export const MainSection = styled.section`
  background-color: #000;
  height: 45px;
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
`;

export const Text = styled.div`
  p {
    color: #fff;
    font-size: 1.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`;
