import styled from "styled-components";

export const MainSection = styled.section`
  background-color: #000;
  height: 3.1875rem;

  .marquee-container {
    height: inherit;
  }
`;

export const Text = styled.div`
  p {
    color: #fff;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-size: 2.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 0rem;
  }
`;
