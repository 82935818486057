import {
  gloryimg1,
  gloryimg2,
  gloryimg3,
  gloryimg4,
  gloryimg5,
  gloryimg6,
  gloryimg7,
  gloryimg8,
  gloryimg9,
} from "assets";
import React from "react";
import { BuyGloryBtn, MainSection } from "./elements";
import { MainCol, MainRow } from "components/common";
import { Image } from "react-bootstrap";

const BuyComp = () => {
  const data = [
    {
      image: gloryimg1,
    },
    {
      image: gloryimg2,
    },
    {
      image: gloryimg3,
    },
    {
      image: gloryimg4,
    },
    {
      image: gloryimg5,
    },
    {
      image: gloryimg6,
    },
    {
      image: gloryimg7,
    },
    {
      image: gloryimg8,
    },
    {
      image: gloryimg9,
    },
  ];

  return (
    <MainSection>
      <MainRow>
        {data.map((item) => (
          <MainCol lg={4}>
            <Image src={item.image} />
          </MainCol>
        ))}
      </MainRow>
      <div className="btn-div">
        <BuyGloryBtn>BUY $FOMO</BuyGloryBtn>
      </div>
    </MainSection>
  );
};

export default BuyComp;
