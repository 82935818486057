import React from "react";
import {
  Address,
  ImgDiv,
  MainLogoDiv,
  MainSection,
  MainSocialDiv,
  SocialTextDiv,
} from "./elements";
import { Image } from "react-bootstrap";
import {
  dexlogo,
  dextoollogo,
  heroimg,
  telegramicon,
  twittericon,
  uniswaplogo,
} from "assets";
import { MainContainer, ToastMessage } from "components/common";

const HeroComp = () => {
  const contractAddress = "0xFA46430c570b34CC54350302a2CaC3501650526e";

  const handleCopyClick = () => {
    const textarea = document.createElement("textarea");
    textarea.value = contractAddress;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);

    ToastMessage("Success", "Address copied To Clipboard", "success");
  };

  return (
    <MainSection>
      <Address>
        <p onClick={handleCopyClick}>ETH CONTRACT ADDRESS: {contractAddress}</p>
      </Address>
      <ImgDiv>
        <Image src={heroimg} fluid />
      </ImgDiv>

      <MainSocialDiv>
        <SocialTextDiv
          onClick={() => window.open("https://x.com/fomo6900", "_blank")}
        >
          <Image src={twittericon} fluid />
          <p>x.com/fomo6900</p>
        </SocialTextDiv>

        <SocialTextDiv
          onClick={() => window.open("https://t.me/Fomo_6900", "_blank")}
        >
          <Image src={telegramicon} fluid />
          <p>t.me/Fomo_6900</p>
        </SocialTextDiv>
      </MainSocialDiv>

      <MainLogoDiv>
        <Image
          onClick={() => window.open("https:/uniwswap.com", "_blank")}
          src={uniswaplogo}
          fluid
        />
        <Image
          onClick={() =>
            window.open(
              "https://www.dextools.io/app/en/ether/pair-explorer/0xe2baa40ca4e21ea6f2b8a05851bcdf122310cd2d",
              "_blank"
            )
          }
          src={dextoollogo}
          fluid
        />
        <Image
          onClick={() => window.open("https:/dexscreener.com", "_blank")}
          src={dexlogo}
          fluid
        />
      </MainLogoDiv>
    </MainSection>
  );
};

export default HeroComp;
