import styled from "styled-components";

export const MainSection = styled.section`
  background: linear-gradient(180deg, #fff 0%, rgba(255, 255, 255, 0) 52.08%);
  min-height: 100vh;
  overflow: hidden;

  .container {
    padding-bottom: 2.5rem;
  }

  @media (max-width: 767px) {
    min-height: max-content;
  }
`;

export const Address = styled.div`
  p {
    color: #000;
    text-align: center;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;

    @media (max-width: 767px) {
      font-size: 12px;
    }
  }
`;

export const ImgDiv = styled.div`
  text-align: center;
`;

export const SocialTextDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  cursor: pointer;

  @media (max-width: 767px) {
    gap: 0rem;
  }

  p {
    color: #fff;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-size: 2.0625rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 0rem;

    @media (max-width: 767px) {
      font-size: 10px;
    }
  }
`;

export const MainSocialDiv = styled.div`
  display: flex;
  gap: 4rem;
  align-items: center;
  justify-content: center;

  @media (max-width: 767px) {
    img {
      width: 30px;
    }
  }

  @media (max-width: 990px) {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
  }
`;

export const MainLogoDiv = styled.div`
  display: flex;
  gap: 3rem;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  img {
    cursor: pointer;

    @media (max-width: 767px) {
      width: 100px;
    }
  }

  @media (max-width: 990px) {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-top: 1rem;
    justify-content: center;
    align-items: center;
  }
`;
