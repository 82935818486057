import styled from "styled-components";

export const MainSection = styled.section`
  padding-bottom: 4rem;
  img {
    width: 100%;
  }

  .btn-div {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
  }
`;

export const BuyGloryBtn = styled.button`
  color: #fff;
  text-align: center;
  background: #000;
  font-size: 3rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  width: 63.0625rem;
  height: 6.8125rem;
  border: 6px solid #fde54d;

  @media (max-width: 990px) {
    font-size: 1.2rem;
    width: 22.0625rem;
    height: 4.8125rem;
  }
`;
