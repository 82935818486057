import styled from "styled-components";

export const MainSection = styled.section`
  padding-bottom: 4rem;

  .main-dev {
    display: flex;
    gap: 2rem;
    justify-content: center;
    align-items: center;

    @media (max-width: 990px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
`;

export const LeftDiv = styled.div`
  margin-top: 6rem;

  @media (max-width: 990px) {
    margin-top: 0rem;
  }

  h5 {
    color: #fff;
    font-size: 2.0625rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 0.2rem;

    @media (max-width: 990px) {
      text-align: center;
      font-size: 1.5rem;
    }
  }

  p {
    color: #fff;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    @media (max-width: 990px) {
      text-align: center;
      font-size: 1rem;
    }
  }
`;

export const SocialTextDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
  p {
    color: #fff;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-size: 2.0625rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 0rem;

    @media (max-width: 767px) {
      font-size: 1rem;
    }
  }
`;

export const MainSocialDiv = styled.div`
  display: flex;
  gap: 4rem;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  @media (max-width: 990px) {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
  }
`;
