import { Image } from "react-grid-gallery";
import {
  memeimg1,
  memeimg10,
  memeimg11,
  memeimg12,
  memeimg13,
  memeimg14,
  memeimg15,
  memeimg16,
  memeimg17,
  memeimg18,
  memeimg19,
  memeimg2,
  memeimg20,
  memeimg21,
  memeimg22,
  memeimg23,
  memeimg24,
  memeimg25,
  memeimg26,
  memeimg27,
  memeimg28,
  memeimg29,
  memeimg3,
  memeimg30,
  memeimg31,
  memeimg32,
  memeimg33,
  memeimg34,
  memeimg35,
  memeimg36,
  memeimg37,
  memeimg38,
  memeimg39,
  memeimg4,
  memeimg40,
  memeimg41,
  memeimg42,
  memeimg43,
  memeimg44,
  memeimg45,
  memeimg46,
  memeimg47,
  memeimg48,
  memeimg49,
  memeimg5,
  memeimg50,
  memeimg51,
  memeimg52,
  memeimg53,
  memeimg6,
  memeimg7,
  memeimg8,
  memeimg9,
} from "assets";

export const images = [
  {
    src: memeimg1,
  },
  {
    src: memeimg2,
  },
  {
    src: memeimg3,
  },
  {
    src: memeimg4,
  },
  {
    src: memeimg5,
  },
  {
    src: memeimg6,
  },
  {
    src: memeimg7,
  },
  {
    src: memeimg8,
  },
  {
    src: memeimg9,
  },
  {
    src: memeimg10,
  },
  {
    src: memeimg11,
  },
  {
    src: memeimg12,
  },
  {
    src: memeimg13,
  },
  {
    src: memeimg14,
  },
  {
    src: memeimg15,
  },
  {
    src: memeimg16,
  },
  {
    src: memeimg17,
  },
  {
    src: memeimg18,
  },
  {
    src: memeimg19,
  },
  {
    src: memeimg20,
  },
  {
    src: memeimg21,
  },
  {
    src: memeimg22,
  },
  {
    src: memeimg23,
  },
  {
    src: memeimg24,
  },
  {
    src: memeimg25,
  },
  {
    src: memeimg26,
  },
  {
    src: memeimg27,
  },
  {
    src: memeimg28,
  },
  {
    src: memeimg29,
  },
  {
    src: memeimg30,
  },
  {
    src: memeimg31,
  },
  {
    src: memeimg32,
  },
];
