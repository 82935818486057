import styled from "styled-components";

export const MainSection = styled.section`
  padding-bottom: 4rem;

  .main-dev {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 0rem 1rem;

    @media (min-width: 767px) {
      display: none;
    }
  }
`;

export const Title = styled.h5`
  color: #fff;
  text-align: center;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-size: 2.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  @media (max-width: 767px) {
    font-size: 1.5rem;
  }
`;

export const ImgWrapper = styled.div`
  img {
    width: 100%;
  }

  @media (max-width: 767px) {
    display: none;
  }
`;
