import styled from "styled-components";

export const MainSection = styled.section`
  padding-bottom: 2rem;
`;

export const ImgDiv = styled.div`
  text-align: center;
`;

export const Text = styled.p`
  margin: 0;
  text-align: center;
  color: white;
  padding: 0rem 8rem;
  /* Outer stroke effect using -webkit-text-stroke and -webkit-background-clip */
  -webkit-text-stroke: 0.3px #ffb800;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background: linear-gradient(transparent 50%, transparent 50%);
  -webkit-background-clip: text;
  background-clip: text;

  @media (max-width: 990px) {
    padding: 0rem;
  }
`;

export const MainLogoDiv = styled.div`
  display: flex;
  gap: 3rem;
  align-items: center;
  justify-content: center;
  margin-top: 3rem;
  img {
    cursor: pointer;
  }

  @media (max-width: 990px) {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-top: 1rem;
    justify-content: center;
    align-items: center;
  }
`;

export const BuyGloryBtn = styled.button`
  color: #fff;
  text-align: center;
  background: #000;
  font-size: 3rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  width: 63.0625rem;
  height: 6.8125rem;
  border: 6px solid #fde54d;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2rem;

  @media (max-width: 990px) {
    font-size: 1.2rem;
    width: 22.0625rem;
    height: 4.8125rem;
  }
`;
