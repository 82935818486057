import styled from "styled-components";

export const MainSection = styled.section`
  padding-bottom: 4rem;
`;

export const Title = styled.h5`
  color: #fff;
  text-align: center;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-size: 2.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  @media (max-width: 767px) {
    font-size: 2rem;
  }
`;

export const ImgDiv = styled.div`
  margin-top: 2rem;
  padding: 0rem 8rem;

  @media (max-width: 990px) {
    padding: 0rem 1rem;
  }

  img {
    width: 100%;
    height: 706px;

    @media (max-width: 990px) {
      height: 100%;
    }
  }
`;
